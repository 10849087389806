import React from 'react';
// gatsby
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
// assets
import FigmaLogo from '../../../images/figma-logo.svg';
import TSLogo from '../../../images/ts-logo.svg';
import ReactLogo from '../../../images/react-logo.svg';
import NodeLogo from '../../../images/nodejs-logo.svg';
import NestLogo from '../../../images/nest-logo.svg';
import RailsLogo from '../../../images/rails-logo.svg';
// classnames
import clsx from 'clsx';
import itemStyles from '../components/item/cases.module.css';
import styles from './skilent.module.css';
// components
import Layout from '../components/item/layout';
import Team from '../components/team';
import Resume from '../components/resume';
import GetConsultation from 'components/free-consultation';
import VideoCase from '../components/video';
// other
import { meta } from '../../../metaData';
import identificators from 'components/googleAnalyticsIdentificators';

const SkilentCase = () => {
  const data = useStaticQuery(graphql`
    query {
      screen1: file(relativePath: { eq: "skilent-screen-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 2800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "skilent-screen-2.png" }) {
        childImageSharp {
          fluid(maxWidth: 2800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "skilent-screen-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 2800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "skilent-screen-4.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "skilent-screen-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "skilent-screen-6.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen7: file(relativePath: { eq: "skilent-screen-7.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen8: file(relativePath: { eq: "skilent-screen-8.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen9: file(relativePath: { eq: "skilent-screen-9.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen10: file(relativePath: { eq: "skilent-screen-10.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen11: file(relativePath: { eq: "skilent-screen-11.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen12: file(relativePath: { eq: "skilent-screen-12.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen13: file(relativePath: { eq: "skilent-screen-13.png" }) {
        childImageSharp {
          fluid(maxWidth: 2800, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      skilent: file(relativePath: { eq: "career/skilent.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4320) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const technologiesList = [
    {
      title: 'UI Design',
      items: [FigmaLogo],
    },
    {
      title: 'Frontend',
      items: [TSLogo, ReactLogo],
    },
    {
      title: 'Backend',
      items: [NodeLogo, NestLogo],
    },
    {
      title: 'Admin Panel',
      items: [RailsLogo],
    },
  ];

  return (
    <Layout
      score={['Recruiting & HR', 'USA', '2019', 'Finished']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle={<>Skilent&nbsp;— {'\n'}next-generation recruiting software</>}
      subTitle="How Brocoders developed a fully-featured platform to help hiring managers and recruiters optimize their workflow"
      metaData={meta.skilent}
    >
      <div className={itemStyles.inner}>
        <p className={itemStyles.preamble}>
          COMTECH LLC is a leading IT consulting company entrusted by the US Federal Government as
          well as State and Local Government clients to provide enterprise-grade technology and
          services solutions.
        </p>
        <p className={itemStyles.preamble}>
          We managed to come up with fruitful business partnerships with Shailendra Sharma, CEO, and
          Co-Founder at COMTECH LLC. Since early 2018, we've been combining our efforts to create a
          cutting-edge solution designed to meet the highest standards of the HR management
          practices. We are sincerely proud to have a partner that shares our outside-the-box
          mentality along with a business mindset.
        </p>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={itemStyles.paragraph}>
          As one of the top-rated IT companies with a focus on delivering talent acquisition
          services, COMTECH needed a top-notch software solution to automate critical internal
          processes and propel their business to the next level.
        </p>
        <p className={itemStyles.paragraph}>
          Shailendra Sharma reached out to Brocoders to engineer a web-driven system that would
          allow for boosting the overall company's performance. Thanks to our substantial expertise
          in the software development field, cost-effective approaches and the ability to deliver
          products under short timelines, we won the client's confidence and trust.
        </p>
        <p className={itemStyles.paragraph}>
          First and foremost, we started with in-depth research of the partner's business processes
          to acquire insights into the primary challenges and issues we could face during the
          development phases. Our ground-breaking goal was to deliver the code of competitive
          quality while following the industry-specific insights and state-of-the-art tech trends.
        </p>
        <p className={itemStyles.paragraph}>
          To see the big picture, we hold a few workshops with the COMTECH team, during which they
          provided us with additional project documentation and shared their visions of the final
          product.
        </p>
      </div>
      <Img fluid={data.screen1.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Working process</h2>

        <VideoCase image={data.skilent.childImageSharp.fluid} source="skilent_final" />

        <p className={itemStyles.paragraph}>
          To estimate technical feasibility and define the app's primary functionality, we created a
          low-fidelity UXPin prototype following the client's technical specifications and business
          logic. With such a preliminary development model, we could also gather early feedback as
          well as find the most critical gaps early on.
        </p>
        <p className={itemStyles.paragraph}>
          Based on the prototype and the reviewed data, the Brocoders team's scope of work at the
          initial stages included the implementation of the following features:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Three key user types: Hiring Manager, Recruiter, Candidate</li>
          <li>Intuitive job searching tools</li>
          <li>Lightning-fast, interactive UI</li>
          <li>Informative dashboards</li>
          <li>Multi-functioning control panel</li>
          <li>In-built calendar for events and scheduled calls</li>
          <li>Communication tool (a messenger between recruiters and candidates)</li>
          <li>Customizable user profiles</li>
          <li>Bounty Points rewards system</li>
        </ul>
        <p className={itemStyles.paragraph}>
          After requirements elicitation, Brocoders went with the agile development methodology to
          ensure excellent transparency as well as let the COMTECH team to be involved throughout
          the entire project lifecycle. Given a tight deadline set by the client, the agile-driven
          scheduled sprints of 1-4 weeks allowed us to deliver the solution within the shortest
          possible time.
        </p>
        <h2 className={itemStyles.subtitle}>Technologies</h2>
        <p className={itemStyles.paragraph}>
          We opted for Figma to develop design patterns that we used to share with the client to
          acquire their invaluable feedback. On top of that, backed by a multi-year software
          development expertise that's been polished throughout almost 100 projects, the Brocoders
          team deployed the following up-to-date technologies:
        </p>
        <div className={styles.technologies}>
          {technologiesList.map(({ title, items }) => {
            return (
              <div className={styles.technologiesItem}>
                <h4>{title}</h4>
                {items.map((item) => (
                  <img src={item} alt="Technologies Logo" />
                ))}
              </div>
            );
          })}
        </div>
        <h2 className={itemStyles.subtitle}>Two-phase development</h2>
        <p className={itemStyles.paragraph}>
          Once the design patterns were approved by the COMTECH team, the decision was made to build
          a smoothly working MVP in order to validate the product idea and attract early adopters.
          The MVP development took 5-6 months to complete and was finished in the spring of 2019.
        </p>
        <p className={itemStyles.paragraph}>
          After the initial release, the MVP was ready to be tested on end-users, so the COMTECH's
          plan was to reach the largest possible audience. The client approached our team once again
          back in the spring of 2020 with an eye to integrate a rich set of additional features.
        </p>
        <p className={itemStyles.paragraph}>
          Based on the target audiences' feedback, our on-site engineers then rolled out a series of
          bug fixes and improvements, including the integration of a new user type — Super Hiring
          Manager. The product with a newly revamped efficiency was then ready for the final release
          stage 4 months later.
        </p>
      </div>
      <Img fluid={data.screen2.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Our team</h2>
        <p className={itemStyles.paragraph}>
          For this project, Brocoders put together a versatile team of seasoned experts with dozens
          of successful projects under their belts. Our careful attention to detail, along with a
          client-oriented business approach, allowed us to become a long-term COMTECH's partner,
          helping them invigorate their employees and customers with a multi-functioning recruiting
          and talent acquisition software.
        </p>
        <p className={itemStyles.paragraph}>
          Brocoders attached a separate team for each development phase:
        </p>
        <Team
          title="MVP development phase"
          color="#286EFA"
          list={[
            { count: 2, title: 'Backend\nengineers' },
            { count: 1, title: 'Frontend\nengineer' },
            { count: 1, title: 'Project\nmanager' },
            { count: 1, title: 'UI/UX\ndesigner' },
            { count: 1, title: 'QA\nengineer' },
          ]}
        />
        <Team
          title="Final development phase"
          color="#286EFA"
          list={[
            { count: 1, title: 'Frontend\nengineer' },
            { count: 1, title: 'Backend\nengineer' },
            { count: 1, title: 'QA\nengineer' },
            { count: 1, title: 'Project\nmanager' },
          ]}
        />
        <Resume services={['product', 'digital', 'devOps']} />
      </div>
      <Img fluid={data.screen3.childImageSharp.fluid} className={itemStyles.container} alt="" />
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>Delivered Solution</h2>
        <p className={itemStyles.paragraph}>
          To mitigate potential risks and simplify the integration processes, Brocoders held
          frequent workshops with the COMTECH team. Thanks to our dynamic, collaborative work, the
          client received a cutting-edge recruiting software that is geared to enhance their
          business KPIs while reinforcing their position of a reliable IT services provider.
        </p>
        <p className={itemStyles.paragraph}>
          The Skilent Talent Acquisition tool features all the required functionality in compliance
          with the client's needs. The key benefits of this all-in-one platform include:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>
            Quick and easy access to essential information (like job type, applicants' experience,
            skills, qualifications, location details, work preferences, etc.)
          </li>
          <li>Sophisticated search engine to find matching candidates or suitable jobs</li>
          <li>Data-driven dashboards for all users</li>
          <li>Rock-solid data security in accordance with industry standards, including GDPR</li>
          <li>Improved and automated HR processes — scheduling, data management, etc.</li>
          <li>
            Bounty points that can be redeemed for cash or gift cards (available for recruiters or
            candidates who bring in new candidates)
          </li>
        </ul>
      </div>
      <div className={itemStyles.container}>
        <GetConsultation
          title="Let’s start HR Tech project together"
          text="Empowering Recruiting&HR businesses with multi-functioning solutions"
          linkText="Get free consultation"
          linkId={identificators.GET_A_FREE_CONSULTATION_BANNER}
        />
      </div>
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>User personas</h2>
        <p className={itemStyles.paragraph}>
          The resulting web solution comprises three major user types, each with its own
          functionality features:
        </p>
        <h2 className={itemStyles.subtitle}>Hiring Manager can:</h2>
        <p className={itemStyles.paragraph}>
          Currently, the Manager can set up an account using the emails with specific domains only,
          since the platform works solely within the infrastructure of COMTECH LLC.
        </p>
        <Img fluid={data.screen4.childImageSharp.fluid} alt="" />
        <p className={clsx(itemStyles.paragraph, styles.description)}>Manager profile</p>

        <p className={itemStyles.paragraph}>
          After the confirmation procedure, the Manager can fill in a profile and invite Recruiters.
          A standard user cannot create a Recruiter account by himself.
        </p>
        <Img fluid={data.screen5.childImageSharp.fluid} alt="" />
        <p className={clsx(itemStyles.paragraph, styles.description)}>Invitation to the system</p>

        <p className={itemStyles.paragraph}>
          The Manager can create job requisitions and write an accurate and convincing job
          description to attract the best candidates. The jobs are divided into four types:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Opened</li>
          <li>On hold</li>
          <li>Closed</li>
          <li>Expired</li>
        </ul>
        <Img fluid={data.screen6.childImageSharp.fluid} alt="" />
        <p className={clsx(itemStyles.paragraph, styles.description)}>Job requisition</p>

        <p className={itemStyles.paragraph}>
          The Manager can create a list of customers for whom the talent acquisition services will
          be applied (candidates searching). Clients may be either internal (COMTECH) or external
          ones (other companies, such as Google or Amazon).
        </p>
        <p className={itemStyles.paragraph}>
          The Manager can use a calendar to add new events or leave a note about the time he would
          be unavailable for interviews, to name a few. The calendar is visible to the Manager and
          the Recruiters from his team. Recruiters can arrange job interviews for upcoming events
          using the Manager's calendar.
        </p>
        <p className={itemStyles.paragraph}>
          Using a dashboard, the Manager can acquire relevant statistics and information on the
          Recruiters from his team, such as:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>The total number of Recruiters</li>
          <li>The total number of opened, closed or on-hold job status</li>
          <li>Top 5 Recruiters in closed jobs</li>
          <li>The total number of closed jobs for a particular customer</li>
        </ul>
        <Img fluid={data.screen7.childImageSharp.fluid} alt="" />
        <p className={clsx(itemStyles.paragraph, styles.description)}>Manager dashboard</p>

        <h2 className={itemStyles.subtitle}>Recruiter can:</h2>
        <p className={itemStyles.paragraph}>
          An invitation from the Manager is the only way for the Recruiter to join the platform. The
          Recruiter joins "the particular team" of the Manager who sent the invitation. The
          Recruiter can see the list of job requisitions assigned to him by the Manager. The
          Recruiter can keep track of all the Candidates that are in the system and perform a search
          using required filters.
        </p>
        <Img fluid={data.screen8.childImageSharp.fluid} alt="" />
        <p className={clsx(itemStyles.paragraph, styles.description)}>Candidate searching page</p>

        <p className={itemStyles.paragraph}>
          The Recruiter can attract, select and appoint the Candidate only for the job requisitions
          that were assigned to him by the Manager beforehand. Once the Candidate accepts the job,
          the Recruiter can approve the job application and start a chat with the Candidate, after
          which he can set up an interview with the Manager.
        </p>
        <p className={itemStyles.paragraph}>
          Using a dashboard, the Recruiter can view the rating of the top 5 Recruiters from his
          team. Recruiters can also analyze statistics on either closed or opened jobs that they are
          working on at the moment.
        </p>
        <Img fluid={data.screen9.childImageSharp.fluid} alt="" />
        <p className={clsx(itemStyles.paragraph, styles.description)}>Recruiter dashboard</p>

        <h2 className={itemStyles.subtitle}>Candidate can:</h2>
        <p className={itemStyles.paragraph}>
          Any user can create a Candidate account, fill in the personal data and upload his CV to a
          user profile. The Candidate can view the list of available jobs and keep track of his own
          job applications.
        </p>
        <Img fluid={data.screen10.childImageSharp.fluid} alt="" />
        <p className={clsx(itemStyles.paragraph, styles.description)}>Candidate profile</p>

        <p className={itemStyles.paragraph}>
          The Candidate can search for jobs using various filters, mark the suitable positions as
          well as apply for a job. Before the Candidate can move on to the next stage, the Recruiter
          must approve the job application in the first place.
        </p>
        <Img fluid={data.screen11.childImageSharp.fluid} style={{ marginBottom: '60px' }} alt="" />
        <p className={clsx(itemStyles.paragraph, styles.description)}>Job searching page</p>

        <p className={itemStyles.paragraph}>
          Using a calendar, the Candidate can keep track of the arranged interviews or events
          schedule.
        </p>
        <Img fluid={data.screen12.childImageSharp.fluid} alt="" />
        <p className={clsx(itemStyles.paragraph, styles.description)}>Candidate calendar</p>

        <div className={styles.table}>
          <div className={styles.container}>
            <div className={styles.item}>
              <div className={styles.head}>{'Hiring \nManager can'}</div>
              <div className={styles.content}>
                Currently, the Manager can set up an account using the emails with specific domains
                only, since the platform works solely within the infrastructure of COMTECH LLC.
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.head}>Recruiter can</div>
              <div className={styles.content}>
                An invitation from the Manager is the only way for the Recruiter to join the
                platform. The Recruiter joins "the particular team" of the Manager who sent the
                invitation. The Recruiter can see the list of job requisitions assigned to him by
                the Manager. The Recruiter can keep track of all the Candidates that are in the
                system and perform a search using required filters.
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.head}>Candidate can</div>
              <div className={styles.content}>
                Any user can create a Candidate account, fill in the personal data and upload the
                portfolio to a user profile. The Candidate can view the list of available jobs and
                keep track of his own job applications.
              </div>
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>{'Hiring \nManager can'}</div>
              <div className={styles.content}>
                After the confirmation procedure, the Manager can fill in a profile and invite new
                Recruiters. A standard user cannot create a Recruiter account by himself.
              </div>
            </div>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>Recruiter can</div>
              <div className={styles.content}>
                The Recruiter can attract, select and appoint the Candidate only for the job
                requisitions that were assigned to him by the Manager beforehand. Once the Candidate
                accepts the job, the Recruiter can approve the job application and start a chat with
                the Candidate, after which he can set up an interview with the Manager.
              </div>
            </div>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>Candidate can</div>
              <div className={styles.content}>
                The Candidate can search for jobs using various filters, mark the suitable positions
                as well as apply for a job. Before the Candidate can move on to the next stage, the
                Recruiter must approve the job application in the first place.
              </div>
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>{'Hiring \nManager can'}</div>
              <div className={styles.content}>
                The Manager can create job requisitions and write an accurate and convincing job
                description to attract the best candidates. The jobs are divided into four types:
                <br />— Opened
                <br />— On hold
                <br />— Closed
                <br />— Expired
              </div>
            </div>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>Recruiter can</div>
              <div className={styles.content}>
                Using a dashboard, the Recruiter can view the rating of the top 5 Recruiters from
                his team. Recruiters can also analyze statistics on either closed or opened jobs
                that they are working with at the moment.
              </div>
            </div>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>Candidate can</div>
              <div className={styles.content}>
                Using a calendar, the Candidate can keep track of the arranged interviews or events.
              </div>
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>{'Hiring \nManager can'}</div>
              <div className={styles.content}>
                The Manager can create a list of customers for whom the talent acquisition services
                will be applied (candidates searching). Clients may be either internal (COMTECH) or
                external ones (other companies, such as Google or Amazon).
              </div>
            </div>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>Recruiter can</div>
              <div className={styles.content} />
            </div>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>Candidate can</div>
              <div className={styles.content} />
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>{'Hiring \nManager can'}</div>
              <div className={styles.content}>
                The Manager can use a calendar to add new events or define at which time he would be
                unavailable for interviews. The calendar is visible to the Manager and the
                Recruiters from his team. Recruiters can arrange job interviews for upcoming events.
              </div>
            </div>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>Recruiter can</div>
              <div className={styles.content} />
            </div>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>Candidate can</div>
              <div className={styles.content} />
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>{'Hiring \nManager can'}</div>
              <div className={styles.content}>
                Using a dashboard, the Manager can acquire relevant statistics and information on
                the Recruiters from his team, such as:
                <br />— The total number of Recruiters
                <br />— The total number of opened, closed or on-hold job status
                <br />— Top 5 Recruiters in closed jobs
                <br />— The total number of closed jobs for a particular customer
              </div>
            </div>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>Recruiter can</div>
              <div className={styles.content} />
            </div>
            <div className={styles.item}>
              <div className={clsx(styles.head, styles.hide)}>Candidate can</div>
              <div className={styles.content} />
            </div>
          </div>
        </div>
      </div>

      <Img fluid={data.screen13.childImageSharp.fluid} alt="" />
      <div className={clsx(itemStyles.lastItem, itemStyles.inner)}>
        <h2 className={itemStyles.title}>Project results</h2>
        <p className={itemStyles.paragraph}>
          We’ve recently come up with the fact that almost 20,000 active users experienced a load
          performance issue. Our team managed to promptly reduce the load time to 3-5 seconds, but
          there's still more to upgrade. To address critical performance gaps and scalability
          requirements, we are about to implement new updates and optimize the code so that the
          system could support up to 100,000 active users.
        </p>
        <p className={itemStyles.paragraph}>
          The third development stage is already underway, and the client's plans include the
          implementation of new features and functionality enhancements, including Zoom integration.
        </p>
      </div>
    </Layout>
  );
};

export default SkilentCase;
