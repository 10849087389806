import React, { useContext } from 'react';
import useBrowserDetect, { Browser } from 'hooks/useBrowserDetect';
//styles
import styles from './video-modal.module.css';
// context
import { ModalContext } from '../../modal';
//icons
import CloseBtn from '../../../images/career/ic_close.inline.svg';

interface IVideoModalProps {
  poster?: string;
  width?: string;
  height?: string;
  controls?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  preload?: string;
  source: string;
}

function VideoModal({
  source,
  width,
  height,
  poster,
  controls = true,
  loop = false,
  preload = 'auto',
  autoPlay = true,
}: IVideoModalProps) {
  const { closeModal } = useContext(ModalContext);

  const isSafari = useBrowserDetect() === Browser.Safari;

  const onKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.code === 'Escape') {
      closeModal();
    }
  };

  return (
    <div className={styles.modalVideoContainer} onKeyDown={onKeyDown}>
      <video
        width={width}
        height={height}
        controls={controls}
        loop={loop}
        preload={preload}
        autoPlay={autoPlay}
        poster={poster}
        className={styles.video}
        muted={isSafari ? true : false}
      >
        <source src={`${source}.webm`} type="video/webm" />
        <source src={`${source}.mp4`} type="video/mp4" />
      </video>
      <CloseBtn className={styles.closeBtn} onClick={closeModal} />
    </div>
  );
}

export default VideoModal;
