import * as React from 'react';
import VideoModal from '../../../pop-ups/video-modal';
// gatsby
import Img, { FluidObject } from 'gatsby-image';
//hooks
import useModal from '../../../../hooks/useModal';
// styles
import clsx from 'clsx';
import styles from './video.module.css';
//icons
import PlayBtn from '../../../../images/career/pink-btn.inline.svg';

interface IVideoCaseProps {
  image: FluidObject | FluidObject[];
  source: string;
  customStyleContainer?: string;
}

export default function VideoCase({ image, source, customStyleContainer }: IVideoCaseProps) {
  const { handleModal } = useModal();

  return (
    <div
      className={clsx(styles.container, customStyleContainer)}
      onClick={() =>
        handleModal(
          <VideoModal
            source={`${process.env.GATSBY_STRAPI_CDN}/video/${source}`}
            preload="auto"
            autoPlay={true}
            controls
            width="100%"
            height="auto"
          />,
          false
        )
      }
    >
      <Img fluid={image} className={styles.img} alt="" />
      <PlayBtn className={styles.playBtn} />
    </div>
  );
}
